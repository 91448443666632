@use '@angular/material' as mat;

@import '@angular/material/theming';

$primary-color: #17b098;
$accent-color: #4368b0;

$high-color: #cb4e39;
$medium-color: #f18627;
$low-color: #efd500;
$warning-color: #d0d0d0;

$qc-dark-blue-1: #282e43;
$qc-dark-blue-2: #353952;

$qc-grey: #b6bcc1;
$qc-grey-1: #e0e6ea;
$qc-grey-2: #e9edf0;
$qc-grey-3: #f8f9fa;

$qc-text-xs: 10px;
$qc-text-sm: 12px;
$qc-text-md: 14px;
$qc-text-lg: 16px;
$qc-text-xl: 18px;
$qc-text-xxl: 20px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$toolbar-breakpoint: 600px;

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$quality-palette: mat.define-palette(
    (
        50: #e0f6f2,
        100: #b3e7de,
        200: #80d7c9,
        300: #4dc6b3,
        400: #26b8a3,
        500: $primary-color,
        600: #14a58f,
        700: #109882,
        800: #0c8c76,
        900: #067863,
        A100: #5c6da2,
        A200: $accent-color,
        A400: #37548a,
        A700: #22365a,
        contrast: (
            50: black,
            100: black,
            200: black,
            300: black,
            400: black,
            500: white,
            600: white,
            700: white,
            800: white,
            900: white,
            A100: black,
            A200: black,
            A400: black,
            A700: black
        )
    )
);

$my-primary: mat.define-palette($quality-palette, 500);
$my-accent: mat.define-palette($quality-palette, A200, A100, A400);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn
        )
    )
);

$grey-palette: (
    50: #f5f5f5,
    100: #e0e0e0,
    200: #bdbdbd,
    300: #9e9e9e,
    400: #757575,
    500: #616161,
    600: #424242,
    700: #333333,
    800: #212121,
    900: #121212,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff
    )
);

$grey-theme: mat.define-light-theme(
    (
        color: (
            primary: mat.define-palette($grey-palette),
            accent: mat.define-palette($grey-palette, 400)
        ),
        typography: mat.define-legacy-typography-config(),
        density: 0
    )
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.all-legacy-component-themes($my-theme);
@include mat.legacy-tabs-theme($grey-theme);
