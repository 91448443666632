@import 'theme';
@import 'mortensen.scss';

@import 'prismjs/themes/prism.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
@import 'assets/bootstrap/scss/bootstrap-reboot';
@import 'assets/bootstrap/scss/bootstrap-grid';
@import '@ng-select/ng-select/themes/default.theme.css';

body,
html {
    height: 100%;
}

a {
    color: #000000de;
    text-decoration: none !important;

    &:hover {
        color: $accent-color;
        text-decoration: none !important;
    }
}

.accent-color {
    color: $accent-color;
}

.primary-color {
    color: $primary-color;
}

.mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-program-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: 0.5 !important;
}

.widget-loading .spinner {
    height: 25px;
    width: 25px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    right: 0;
}

.widget {
    position: relative;
}

.download-pdf {
    right: 260px;
    top: -40px;
}

@media (min-width: 512px) and (max-width: 960px) {
    .download-pdf {
        top: 16px !important;
        right: 16px !important;
    }

    .noToggle {
        top: 16px !important;
        right: 16px !important;
    }
}

qcbi-executive-sn-view .download-pdf {
    right: 568px;
}

qcbi-executive-view .download-pdf {
    right: 600px;
}

.export-report {
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    display: flex;
    cursor: pointer;

    .export-report-text {
        line-height: 28px;
        text-decoration: underline;
        margin: -71px 5px 0;
    }

    .mat-icon {
        width: 18px;
        height: 18px;
        margin: -71px 8px 0;
    }
}

.qc-widget {
    mat-spinner {
        position: absolute;
        top: -17px;
        right: -15px;

        &.large-spinner {
            top: 50vh;
            right: 37%;
        }
    }
}

.widget-no-selectable {
    border-bottom-width: 1px;
    border-color: #d6dde3;
}

table {
    width: 100%;
}

mat-spinner {
    &.table-spinner {
        position: absolute;
        top: auto;
        margin-top: 5px;
        right: auto;
    }
}

.table-scroll {
    overflow-x: auto;
    overflow-y: clip;
}

th.mat-sort-header-sorted {
    color: black;
}

.mat-paginator-container {
    justify-content: center !important;
}

.mat-row-custom {
    height: 42px !important;
}

.example-element-detail {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
}

tr.detail-row-custom {
    height: 0 !important;
}
tr.mat-row-custom {
    .mat-cell {
        border-bottom-width: 0;
    }

    &.expanded-row {
        .mat-cell {
            border-bottom-width: 1px;
        }
    }

    &.is-selectable {
        cursor: pointer;

        &:not(.selected):hover {
            background: #f1f4f6;
        }
    }

    &.selected {
        background: $primary-color;

        td.mat-cell {
            color: #fff !important;
        }
    }
}

.expandable-table {
    position: relative;
}

.expandable-table > tbody > tr.mat-row-custom {
    position: relative;
    cursor: pointer;

    td:first-child {
        padding-left: 40px !important;
    }

    &:after {
        content: '';
        position: absolute;
        left: 10px;
        margin-top: 14px;
        transition: all 300ms;
        width: 8px;
        height: 8px;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        transform: rotate(-45deg);
    }

    &.expanded-row:after {
        transform: rotate(45deg);
        margin-top: 12px;
    }
}

li:focus,
li > span:focus {
    outline: none !important;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0;
}

.icon-view {
    color: #ccc;
}

.mat-button-toggle-checked .icon-view {
    color: $accent-color;
}

mat-button-toggle {
    .mat-button-toggle-label-content {
        line-height: 36px !important;
        padding: 0 5px !important;
    }
}

.to-front-button {
    width: 100%;
}

button:focus,
*:focus {
    outline: 0 !important;
}

nav > ul > li {
    cursor: pointer;
}
.no-pointer {
    cursor: none;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.mat-sort-header-container {
    padding-right: 1em;
    min-width: max-content;

    .mat-sort-header-button {
        text-transform: capitalize;
        font-weight: bold;
        color: black;
    }
}

.mat-column-Quality-Of-Cloud {
    padding-right: 10px !important;
}

th.is-number .mat-sort-header-container {
    justify-content: flex-end;
}

td.mat-cell > .is-number {
    text-align: right;
    padding-right: 10px;
}

.chipset {
    justify-content: flex-end;
    padding-right: 10px;
}

.is-hidden {
    display: none;
}

.mat-cell {
    label:not(.mat-form-field-label) {
        padding-right: 2em;
        white-space: pre-wrap;
        min-width: max-content;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
    }

    .config-checkbox {
        label {
            padding: 0;
            white-space: nowrap;
        }
    }

    .link-button {
        font-size: 20px;
    }

    .link-button:hover {
        color: seagreen;
        cursor: pointer;
    }

    .hyperlink-column {
        color: $accent-color;
        cursor: pointer;
    }

    .hyperlink-column:hover {
        color: $accent-color;
        text-decoration: underline;
        cursor: pointer;
    }

    %severity-cell {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px !important;
    }

    .text-low {
        background-color: $low-color;
        color: black;
        @extend %severity-cell;
    }

    .text-medium {
        background-color: $medium-color;
        color: white;
        @extend %severity-cell;
    }

    .text-high {
        background-color: $high-color;
        color: white;
        @extend %severity-cell;
    }

    .text-warning {
        background-color: $warning-color;
        color: black;
        @extend %severity-cell;
    }

    .has-vulnerabilities {
        font-weight: bold;
        color: #45855d;
    }

    .red {
        font-weight: bold;
        color: $high-color;
    }

    .cell-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &.mat-column-Issue-Type,
    &.mat-column-Impact,
    &.mat-column-Remediation {
        label {
            min-width: auto !important;
        }
        min-width: 25em;
        max-width: 25em;
        padding-right: 1em;
    }

    &.mat-column-CE-Name {
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 16px;
        max-width: 20em;
        min-width: 20em;
        label {
            display: inline;
        }
    }

    &.mat-column-Technical-Debt--Hours-,
    &.mat-column-Number-Of-Issues,
    &.mat-column-Written-Off {
        text-align: center !important;
    }

    &.mat-column-Severity {
        position: relative;
        min-width: 9em;
        max-width: 9em;
    }

    &.mat-column-Application,
    &.mat-column-Impact-Area,
    &.mat-column-CE-Type,
    &.mat-column-Package-Type,
    &.mat-column-Source,
    &.mat-column-Library-Name,
    &.mat-column-Version {
        label {
            min-width: auto !important;
        }
        min-width: 10em !important;
        max-width: 10em !important;
    }

    &.mat-column-Created-By,
    &.mat-column-Created-On,
    &.mat-column-Updated-By,
    &.mat-column-Updated-On,
    &.mat-column-Installed-On,
    &.mat-column-Installed-By,
    &.mat-column-Written-Off-On,
    &.mat-column-Developers,
    &.mat-column-Detection-Date {
        label {
            white-space: pre-line;
            min-width: auto !important;
        }
        min-width: 9em;
        max-width: 9em;
    }

    &.mat-column-Loc {
        label {
            min-width: auto !important;
        }
        min-width: 5em;
        max-width: 5em;
    }
}

.mat-option {
    height: 2.5em !important;
}

.mat-label {
    font-size: 15px;
}

.no-scans-text {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
}

.mat-column-doc {
    padding-right: 8px;
}

.mat-header-cell {
    background: #f1f4f6;
    border: 1px solid #d6dde3;
    text-transform: capitalize;
    font-weight: bold;
    color: black !important;
    font-size: 14px;
}

.mat-header-cell,
.mat-cell {
    padding-left: 10px !important;

    &.mat-column-Issues-Related {
        .mat-sort-header-container {
            justify-content: normal !important;
        }
    }
}

.mat-header-cell:last-of-type {
    padding-right: 0 !important;
}

.mat-header-row {
    height: 36px !important;
}

.download-icon-size {
    font-size: 1.2em;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $primary-color;
    transition: all 300ms;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(23, 176, 152, 0.9);
}
.issue-type-mat-select-panel {
    min-width: 40em !important;
}

.chart-title-lg {
    font-size: 1.425em;
}

.error {
    h1 {
        text-align: center;
        font-size: 34px;
        opacity: 0.5;
        margin-top: 50px;
    }
}

.cdk-overlay-container .cdk-overlay-pane .bestPractices {
    margin-top: 7%;
    min-width: 620px !important;
    animation-name: opacityDelay !important;
    animation-duration: 0.3s !important;
}

@keyframes opacityDelay {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

qcbi-executive-view ul > li .qc-widget {
    min-height: 150px;
}

.compact-tooltip {
    .link-button {
        font-size: small;
        text-decoration: underline;
        color: seagreen;
    }

    .link-button:hover {
        color: blue;

        cursor: pointer;
    }
}

.no-chart-tooltip {
    mat-icon {
        top: -4px !important;
    }
}

.bar-chart-tooltip {
    mat-icon {
        top: 4px !important;
    }
}

qcbi-lw-header .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.no-views-padding {
    padding: 1.5em 0;
}

.mat-calendar-cell-semi-selected {
    background-color: #00c86317;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
    border-radius: 100% 0 0 100%;
    background-color: #00c86317;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    border-radius: 0 100% 100% 0;
    background-color: #00c86317;
}

.mat-expansion-panel:last-of-type {
    margin-bottom: 8px;
}

.mat-expansion-indicator {
    height: 30px !important;
}

.grid-title {
    font-size: 1.425em;
    text-align: center;
    padding: 32px;
}

@media (max-width: 1600px) {
    .gauge {
        height: 120px;
        align-items: inherit;
        float: left;
    }
}

.counter {
    position: absolute;
    top: 13.75em;
    right: 2.5em;
}

@media (max-width: 1446px) {
    .counter {
        position: absolute;
        top: 16em;
        right: 2.5em;
    }
}

.mat-simple-snackbar-action {
    color: $primary-color !important;
}

.red-border {
    border: 1px solid red;
}

.table-sub-header.mat-header-cell {
    background: white;
    padding-right: 10px !important;
    div {
        text-align: center;
        font-size: 1.2em;
    }
}

qcbi-rm-large .qc-widget .mat-row-custom {
    height: 56px !important;
}

qcbi-rm-large .expandable-table > tbody > tr.mat-row-custom:after {
    margin-top: 25px !important;
}

qcbi-rm-detail tr {
    border: 1px solid #e4e7eb !important;
}

qcbi-rm-detail .mat-header-row {
    height: 56px !important;
}

qcbi-rm-large .example-element-detail > div {
    width: 100%;
}

qcbi-rm-large .example-element-detail mat-paginator {
    background-color: #f9f9f9 !important;
}

qcbi-rm-large .mat-column-expandedDetail {
    background-color: #f9f9f9;
}

qcbi-dc-large mat-paginator {
    visibility: hidden;
}

qcbi-dc-large qcbi-chart-detail mat-paginator {
    visibility: visible !important;
}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background: $primary-color;
}

.ng-select.ng-select-focused .ng-select-container:after {
    border-color: $primary-color;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: $primary-color;
}

qcbi-rm-large qcbi-rm-detail table {
    table-layout: fixed;

    & .mat-column-Issue-Type {
        width: 300px;
    }
}

qcbi-governance-overview-panel .mat-column-Scan-Id {
    display: none;
}

qcbi-governance-overview-panel .mat-column-Op-Scan {
    display: none;
}

.popup-button {
    text-decoration: underline;
}

.popup-button:hover {
    color: $primary-color;
    cursor: pointer;
}

.pr-status-button {
    color: $accent-color;
    text-decoration: underline;
}

.pr-status-button:hover {
    color: $primary-color;
    cursor: pointer;
}

.h-half {
    height: 50%;
}

.mat-column-Quality-Gate-Result-Message,
.mat-column-QgSkip,
.mat-column-QgSkipReason {
    display: none;
}

.mat-column-Update-Set-ID,
.mat-column-Update-Set-User,
.mat-column-Update-Set-Scan-Date {
    display: none;
}

@media (min-width: 975px) {
    .no-chart-width {
        min-width: 300px;
    }
}

.mat-column-Sys-Id {
    display: none;
}

.navigation-tab-item span:focus {
    background: #f1f4f6;
}

.qc-widget:focus {
    background: #f1f4f6;
}

.qg-banner-link {
    &:focus {
        font-weight: bold;
    }
    color: #fff !important;
    text-decoration: underline;
}

.avoid-compare {
    .d2h-ins {
        background-color: #f8fafd !important;
        border-color: #d5e4f2 !important;
    }

    .d2h-code-line-prefix {
        color: #f8fafd !important;
    }
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

.mat-expansion-panel-header-title {
    margin: 0 !important;
}

.mat-expansion-panel-header {
    padding: 0 0 0 24px !important;
}

mat-expansion-panel {
    max-width: 60vw;
}

ng-code-diff {
    max-width: 60vw;
}

.mat-mini-fab.mat-accent {
    background-color: #c5c4c4 !important;
}

.mat-raised-button.mat-primary:not(:disabled) {
    background-color: $primary-color !important;
}

.mat-loading .mat-button-wrapper {
    visibility: hidden;
}

.mat-loading .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

table {
    border-right: 2px transparent !important;
}

.mat-header-cell.resize-active.resize-ghost-element {
    background: #f1f4f6;
    border-bottom: 0;
    border-top: 1px solid #d6dde3;
    button {
        margin-top: 2px;
        justify-content: normal !important;
        height: inherit;
        position: inherit;
    }
    padding-top: 5px;
}

qcbi-governance-view .mat-column-Scan-Id,
qcbi-governance-view .mat-column-Op-Scan {
    display: none;
}

treetable th {
    background: #f1f4f6 !important;
    color: #3a3b3f !important;
    height: 50px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

treetable table {
    box-shadow: none !important;
}

treetable tbody td {
    border-left: none !important;
}

.custom-tooltip {
    background-color: #efefef;
    color: #575962;
    padding: 16px;
    border-radius: 8px;
    h4 {
        font-weight: bold;
        font-size: 1em;
    }
}

.rm-app {
    text-transform: uppercase;
    background: $accent-color;
    padding: 2px 16px;
    border-radius: 15px;
    width: fit-content;
    color: white;
}

.rm-portal {
    text-transform: uppercase;
    background: $primary-color;
    padding: 2px 16px;
    border-radius: 15px;
    width: fit-content;
    color: white;
}

.rm-buildcheck {
    text-transform: uppercase;
    background: #554596;
    padding: 2px 16px;
    border-radius: 15px;
    width: fit-content;
    color: white;
}

.write-off-type {
    text-transform: capitalize;
    background: #d3ffb9;
    padding: 2px 16px;
    border-radius: 2px;
    width: fit-content;
    color: black;
    font-size: 11px;
}

treetable .mat-column-Configuration-Elements {
    white-space: nowrap;
}

.baseline-option {
    background: #d8b5af;
    font-weight: bold;
    &:hover {
        background: #deaea6 !important;
    }
}

.custom-tooltip {
    background: rgb(112, 112, 112) !important;
    color: white !important;
    font-size: 12px;
}

.ng-select.ng-select-single .ng-select-container {
    height: 40px !important;
}

.mat-drawer {
    .mat-tab-group {
        &:not(.expanded) {
            .mat-ink-bar {
                width: 64px !important;
            }

            .mat-tab-label {
                &:not(.mat-tab-label-active) {
                    display: none;
                }
                width: 64px !important;
                max-width: 64px !important;
                min-width: 64px !important;
                padding: 0 !important;
            }

            .example-stretched-tabs {
                max-width: 64px;
            }
        }

        &.expanded {
            .mat-ink-bar {
                width: 120px !important;
            }

            .mat-tab-label {
                width: 120px !important;
                max-width: 120px !important;
                min-width: 120px !important;
                padding: 0 !important;
            }

            .example-stretched-tabs {
                max-width: 240px;
            }
        }

        .mat-tab-header-pagination {
            display: none !important;
        }

        .example-tab-icon {
            color: white !important;
        }
    }
}

qcbi-release-management-view .mat-column-Name,
qcbi-release-management-view .mat-column-Owner {
    width: 200px !important;
    max-width: 200px !important;
}

qcbi-table-configuration .mat-checkbox-checked .mat-ripple-element {
    background: #767676 !important;
}

qcbi-table-configuration
    .mat-checkbox-checked.mat-accent
    .mat-checkbox-background {
    background: #767676 !important;
}

.mat-column-peerReviewId {
    display: none;
}
/*
qcbi-debt-manager-drawer-content .mat-tab-label {
    padding: 0 8px;
    font-size: 20px;
    letter-spacing: -0.07px;
    line-height: 20px;
}

qcbi-debt-manager-drawer-content .mat-tab-label {
    padding: 0 !important;
    min-width: 132px !important;
}
*/

.mat-badge-content {
    font-size: 10px !important;
}

.mat-chip-list-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
}

.notifications-panel {
    max-width: none !important;
}

.profile-menu {
    width: 350px !important;
    max-width: 350px !important;
    min-width: 350px !important;
    .mat-menu-content {
        padding: 0 !important;
    }
}

qcbi-early-detection-large .mat-column-Scan-Id {
    display: none !important;
}

.mat-column-Element-Id {
    display: none;
}

.default-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(23, 176, 152, 0.54) !important;
}

.default-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $primary-color !important;
}

.show-diff {
    font-weight: bold;

    &.red {
        color: #ec6a5e;
    }

    &.green {
        color: #62d393;
    }

    &.blue {
        color: #5e9aec;
    }
}

.select-all-action {
    text-decoration: underline;
    color: $accent-color;
    margin-left: 8px;
    cursor: pointer;
}

.editable-tags-container {
    .menu-button {
        visibility: hidden;
    }
    &:hover {
        .menu-button {
            visibility: visible;
        }
    }
}

.red tr td:first-child,
.orange tr td:first-child,
.green tr td:first-child {
    position: relative;
}

.red tr td:first-child:before,
.orange tr td:first-child:before,
.green tr td:first-child:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.red tr td:first-child:before {
    background: red;
}

.orange tr td:first-child:before {
    background: orange;
}

.green tr td:first-child:before {
    background: darkgreen;
}

.red-tab {
    color: red;

    & > span.active {
        border-color: red;
    }
}

.orange-tab {
    color: orange;

    & > span.active {
        border-color: orange;
    }
}

.green-tab {
    color: darkgreen;

    & > span.active {
        border-color: darkgreen;
    }
}

.download-pdf:focus {
    border: 1px solid black;
}

.link-url-top:focus {
    color: $accent-color;
}

.mat-info-icon {
    border: 1px solid transparent;
    border-radius: 100%;
    width: 17px !important;
    height: 17px !important;
}

a:focus {
    .mat-info-icon {
        border: 1px solid $accent-color;
        border-radius: 100%;
        width: 17px;
        height: 17px;
    }
}

th.mat-column-Select {
    padding-right: 16px !important;
}

th.mat-column-doc {
    padding-right: 8px !important;
}

.mat-option:not(.mat-option-disabled):not(.view-red) {
    color: rgba(0, 0, 0, 1) !important;

    .mat-option-pseudo-checkbox {
        border-color: rgba(0, 0, 0, 0.64) !important;

        &.mat-pseudo-checkbox-checked {
            background: rgba(0, 0, 0, 0.64) !important;
            border-color: transparent !important;
        }
    }
}

.action-disabled {
    opacity: 0.5;
    cursor: auto !important;
}

.opened-after-qg-activation {
    background: #ffffdc !important;
}

.mat-column-OpenedAfterQgActivation {
    display: none;
}

.mat-column-RowWithErrors {
    display: none;
}

.mat-column-Scan-Id {
    display: none;
}

.clickable-rows tbody tr {
    cursor: pointer;
}

qcbi-debt-manager-drawer-content .line-num2 {
    display: none;
}

qcbi-code-duplication-large .mat-column-expandedDetail {
    background-color: rgb(249 249 249);
}

.snackbar-error {
    background: darkred !important;
}

qcbi-code-duplication-large .mat-column-Dbid,
qcbi-code-duplication-large .mat-column-Id {
    display: none;
}

.mat-column-IssueId,
.mat-column-CeId {
    display: none;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
}

.row-with-errors {
    background: rgba(255, 0, 0, 0.1) !important;
}

.mat-column-Log {
    max-width: 20vw;

    & .cell-text {
        white-space: normal !important;
        padding: 8px;
    }
}

.avatar-autocomplete {
    .mat-form-field-flex {
        height: 42px;
    }
    .mat-form-field-prefix {
        height: 30px;
    }
    .mat-form-field-suffix {
        top: 5px !important;
        .mat-icon:not(.hide) {
            cursor: pointer;
            visibility: hidden;
        }

        .mat-icon.hide {
            display: block;
            visibility: hidden;
            pointer-events: none;
            cursor: none;
        }
    }

    &:hover .mat-form-field-suffix .mat-icon {
        display: block !important;
        visibility: visible;
    }
}

.mat-datepicker-toggle-default-icon {
    margin: auto !important;
}

td.mat-column-Quality-Gate- div {
    color: white;
    border-radius: 24px;
    text-align: center;
    text-transform: uppercase;
    padding: 2px 16px;

    &.legacy {
        background: $low-color;
    }

    &.blocker {
        background: $high-color;
    }

    &.minor {
        background: $medium-color;
    }
}

.mat-column-Quality-Gate- {
    padding-right: 10px !important;
}

@media (min-width: 512px) and (max-width: 960px) {
}

.gap-8 {
    gap: 0 8px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-column-Action > .approved {
    color: #43b960;
}

.mat-column-Action > .rejected {
    color: #b95143;
}

.mat-column-Action > .pending {
}

.is-current-pr {
    background: #f6f6f8 !important;
}

@media (max-width: 768px) {
    .instance-info {
        .text-secondary-500 {
            display: none;
        }
    }
}

qcbi-issue-activity-tracking td.mat-column-Action {
    padding: 8px;
    div {
        white-space: normal !important;
    }
}
qcbi-issue-activity-tracking th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    padding-right: 8px !important;
}

.range-date-filter .mat-form-field-suffix {
    top: 0 !important;

    button {
        padding: 0 !important;
        vertical-align: middle !important;
    }

    .mat-datepicker-toggle-default-icon {
        font-size: 1rem;
    }
}
.picker-suffix button {
    height: 100% !important;
    aspect-ratio: 1;
    width: fit-content !important;
}

.body-border tbody {
    border: 1px solid #d6dde3;
}

.loading-filter {
    * {
        border-color: transparent !important;
    }

    label {
        opacity: 0;
    }

    .mat-select {
        opacity: 0;
    }

    &.mat-form-field {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mat-form-field-outline {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.05);
        animation: blink 2s ease-in-out 0.5s infinite;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

qcbi-filter {
    min-height: 56px;
}

.launch-scan-button .mat-button-wrapper {
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.no-padding-modal .mat-dialog-container {
    padding: 0;
}

mat-form-field.no-padding .mat-form-field-wrapper {
    padding: 0 !important;
}

.available-filters-menu {
    max-height: 380px;
}

qcbi-selectable-filters-component {
    .mat-form-field-outline {
        height: 40px;
    }
}

.custom-mat-prefix-top {
    .mat-form-field-prefix {
        top: 0.4rem !important;
    }
}

.mat-form-field-flex > .mat-form-field-infix {
    padding: 0.125rem 0 0.625rem 0 !important;
}
.mat-form-field-label-wrapper {
    top: -1.5em;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-0.8em) scale(0.75);
    width: 133.33333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
}
.mat-form-field-flex > .mat-form-field-suffix {
    max-height: 30px;
}

.mat-date-range-input-separator {
    margin: 0 4px 0 21px !important;
}

.mat-date-range-input-start-wrapper {
    min-width: 88px;
}

.mat-form-field-disabled.disabled-yellow .mat-form-field-outline {
    background: #fffbe7;
}

qcbi-actions-card .mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.75) !important;
}
